import React from 'react';
import TitleHeader from '../TitleHeader';
import { FormattedMessage } from 'react-intl';


const StomatologiaEstetyczna = () => {
	return (
		<div>
			<lista />
			<TitleHeader title={<FormattedMessage id="menu.services.aestheticDentistry" />} />
			{/* <TitleHeader title="Stomatologia estetyczna" /> */}

			<div className="grid-two-column content">
				<FormattedMessage id="services.aestheticDentistry.naturalBeauty">
					{(txt) => <h2 className="first-column">{txt}</h2>}
				</FormattedMessage>
				{/* <h2 className="first-column">naturalne piękno</h2> */}
				<FormattedMessage
					id="services.aestheticDentistry.naturalBeauty.description"
					values={{
						span: (msg) => {
							console.log('msg', msg);
							return <strong className="brown">{msg}</strong>;
						}
					}}
				>
					{(...chunks) => <p className="second-column">{chunks}</p>}
				</FormattedMessage>

				{/* <h2 className="first-column">metamorfoza uśmiechu</h2> */}
				<FormattedMessage id="services.aestheticDentistry.smileMetamorphosis">
					{(txt) => <h2 className="first-column">{txt}</h2>}
				</FormattedMessage>
				<p>
					<FormattedMessage
						id="services.aestheticDentistry.smileMetamorphosis.description"
						values={{
							span: (msg) => {
								// console.log('msg', msg);
								return <strong className="brown">{msg}</strong>;
							}
						}}
					>
						{(...chunks) => <p className="second-column">{chunks}</p>}
					</FormattedMessage>
					<ul className="list--disc">
						<FormattedMessage
							id="services.aestheticDentistry.smileMetamorphosis.description.li1"
							values={{
								span: (msg) => {
									// console.log('msg', msg);
									return <strong className="brown">{msg}</strong>;
								}
							}}
						>
							{(...chunks) => <li>{chunks}</li>}
						</FormattedMessage>
						<FormattedMessage
							id="services.aestheticDentistry.smileMetamorphosis.description.li2"
							values={{
								span: (msg) => {
									// console.log('msg', msg);
									return <strong className="brown">{msg}</strong>;
								}
							}}
						>
							{(...chunks) => <li>{chunks}</li>}
						</FormattedMessage>
						<FormattedMessage
							id="services.aestheticDentistry.smileMetamorphosis.description.li3"
							values={{
								span: (msg) => {
									// console.log('msg', msg);
									return <strong className="brown">{msg}</strong>;
								}
							}}
						>
							{(...chunks) => <li>{chunks}</li>}
						</FormattedMessage>
						<FormattedMessage
							id="services.aestheticDentistry.smileMetamorphosis.description.li4"
							values={{
								span: (msg) => {
									// console.log('msg', msg);
									return <strong className="brown">{msg}</strong>;
								}
							}}
						>
							{(...chunks) => <li>{chunks}</li>}
						</FormattedMessage>
						<FormattedMessage
							id="services.aestheticDentistry.smileMetamorphosis.description.li5"
							values={{
								span: (msg) => {
									// console.log('msg', msg);
									return <strong className="brown">{msg}</strong>;
								}
							}}
						>
							{(...chunks) => <li>{chunks}</li>}
						</FormattedMessage>
					</ul>
				</p>

			</div>
		</div>
	);
};
export default StomatologiaEstetyczna;
