import React from 'react';
import StomatologiaEstetyczna from '../../components/Uslugi/StomatologiaEstetyczna';
import Layout from '../../layout/pl';

const StomatologiaEstetycznaComp = (props) => {
	return (
		<Layout {...props}>
			<StomatologiaEstetyczna />
		</Layout>
	);
};

export default StomatologiaEstetycznaComp;
